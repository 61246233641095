<button
  [id]="id"
  type="button"
  class="button-dashboard"
  [class]="additionalClasses"
  [ngStyle]="{
    background: backgroundColor,
    color: textColor
  }"
  (click)="handleClick()"
  [attr.aria-expanded]="ariaExpanded"
  [attr.aria-controls]="ariaControls">
  {{ text }}
</button>
