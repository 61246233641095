import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { ConfiguracionesService } from '../configuraciones.service';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";


const Swal = require('sweetalert2')

@Component({
  selector: 'app-modal-create-country',
  templateUrl: './modal-create-country.component.html',
  styleUrls: ['./modal-create-country.component.scss']
})
export class ModalCreateCountryComponent implements OnInit {

  jwt
  is_loading = false
  imagePreview: string | ArrayBuffer | null = null;
  selectedFile: File | null = null; 
  currency: any;


  country_to_create = {
    name: '',
    status_system:'',
    imagen: null
  };

  currencies: any[] = [];
  selectedCurrency: any = null;
  selectedCurrencySymbol: any = null;

  constructor(
    private http: HttpClient,
    private activeModalService: NgbActiveModal,
    private configuracionService: ConfiguracionesService
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
  }


  ngOnInit(): void {
    this.loadCurrency();
  }

  loadCurrency(): void {
    this.configuracionService.getCurrency().subscribe((data) => {
      this.currencies = Object.keys(data).map(key => {
        return {
          code: key,
          ...data[key],
          displayLabel: `${data[key].name} - (${data[key].code})`,
          displayLabelSymbol: `${data[key].name} - (${data[key].symbol_native})`
        };
      });
    });
  }
 
 

  async onSubmit() {

  }

  onCancel() {
    this.activeModalService.close({
      success: false
    });
  }

  clearImage(): void {
    this.selectedFile = null;
    this.imagePreview = null;

    // Limpiar el valor del input de archivo
    const fileInput = document.getElementById('imagenCountry') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];

    if (!file) {
        return;
    }

    const maxSizeInMB = 2;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    if (file.size > maxSizeInBytes) {
        Swal.fire('Archivo muy grande', `El archivo debe ser menor a ${maxSizeInMB}MB`, 'error');
        return;
    }

    const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
    if (!validTypes.includes(file.type)) {
        Swal.fire('Tipo de archivo no permitido', 'Solo se permiten imágenes (PNG, JPG, GIF)', 'error');
        return;
    }

    const reader = new FileReader();
    reader.onload = () => {
        this.imagePreview = reader.result;
    };
    reader.readAsDataURL(file);

    this.country_to_create.imagen = file;
}

}
