import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-card-status-sales",
  templateUrl: "./card-status-sales.component.html",
  styleUrls: ["./card-status-sales.component.scss"],
})
export class CardStatusSalesComponent implements OnInit {
  @Input() text: string = "";
  @Input() number: string = "";
  @Input() icon: string = "";
  @Input() colorFont?: string = "#FFF";
  @Input() bgColor: string = "";
  constructor() {}

  ngOnInit(): void {}
}
