import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { Subscription, lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCreateCountryComponent } from 'src/app/modal-create-country/modal-create-country.component';

const Swal = require('sweetalert2')

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent implements OnInit {
  private jwt
  private routeSub: Subscription;
  country_id
  country_to_edit

  countries: any

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
    this.country_to_edit = {}
    this.routeSub = this.route.params.subscribe(params => {
      this.country_id = params.country_id
    })
  }

  ngOnInit(): void {

    if (this.country_id >= 0) {
      this.get_data_from_country()
    } else {
      this.refresh_data()
    }
  }

  async get_data_from_country() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('country_id', this.country_id)


    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/get_info_by_countryID`, data))
    
    if (response.success) {
  
       this.country_to_edit = response.country   
    }
  }

  async refresh_data(){
    const data = new FormData()
    data.append('jwt', this.jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/get_list_of_countries`, data))
    if (response.success) {
      this.countries = response.payload.countries
    }

  }

  async cancel(){
 
    Swal.fire('Cancelado', 'La operación ha sido cancelada.', 'info');
    this.router.navigate(['/countries'])
  }

  agregar_country_modal() {
    const dialogRef = this.modalService.open(ModalCreateCountryComponent, {
      size: 'lg'
    })
    dialogRef.componentInstance.jwt = this.jwt

    dialogRef.result.then((data) => {
      console.log(data)
      if (data.success) {
        this.countries = data.payload.countries

      }
    })
  }

}
