import { Input,Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { lastValueFrom } from 'rxjs';

const Swal = require('sweetalert2')

@Component({
  selector: 'app-modal-create-customer',
  templateUrl: './modal-create-customer.component.html',
  styleUrls: ['./modal-create-customer.component.scss']
})
export class ModalCreateCustomerComponent implements OnInit {

  jwt

  customer_id

  customer_to_create = {
    nombres: '',
    apellidos: '',
    rut:'',
  }

  shouldBreak = false;

  customer_fields: []
  merges = []
  is_loading = false
  tienda_selected
  
  constructor(
    private http: HttpClient,
    private activeModalService: NgbActiveModal
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
    this.customer_id = JSON.parse(localStorage.user).customer_id
    this.tienda_selected = localStorage.getItem('tienda_selected')

  }

  async ngOnInit() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Customer/get_list_of_customer_fields`, data))
  
    if (response.success) {
       this.customer_fields = response.customer_fields
       this.merges = []
       if (this.customer_fields) {
         for (let index = 0; index < this.customer_fields.length; index++) {
           const merge = this.customer_fields[index];
           
           this.merges.push({
             'id': merge['id'],
             'name': merge['name'],
             'type': merge['type'],
             'required': merge['required'],
             'value':''
           })
         }

      }
    }
  }


  validar() {
    let encontrado = true;

    if (!this.customer_to_create.nombres) {
      Swal.fire('Ingrese nombre del cliente', '', 'error')
      return false
    }

    if (!this.customer_to_create.apellidos) {
      Swal.fire('Ingrese apellido del cliente', '', 'error')
      return false
    }

    if (!this.customer_to_create.rut) {
      Swal.fire('Ingrese el RUT', '', 'error')
      return false
    }

    this.merges.forEach(element => {
      if (encontrado == false) return;
      
      if(element.required == 1){
        if (!element.value) {
            Swal.fire('Ingrese '+element.name, '', 'error')
            encontrado = false
        }

        if(element.type == 'email'){
          if(!this.validar_correo(element.value)){
            Swal.fire('Ingrese correo electrónico válido', '', 'error')
            encontrado = false
          }
        }else if(element.type == 'number'){

          if (!this.validarNumber(element.value)) {
            Swal.fire('Ingrese número válido', '', 'error')
            
            encontrado = false
          }
        }
      }else{
        if(element.value != ''){
          if(element.type == 'email'){
            if(!this.validar_correo(element.value)){
              Swal.fire('Ingrese correo electrónico válido', '', 'error')
              encontrado = false
            }
          }else if(element.type == 'number'){
            
  
            if (!this.validarNumber(element.value)) {
              Swal.fire('Ingrese número válido', '', 'error')
              encontrado = false
            }
          }
        }
      }
  
    });

   if(!encontrado){
    return false
   }
   
    return true
  }

  isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  validarNumber(dato: string): boolean {
    if (dato) {
      if (this.isNumber(dato)) {
        return true;
      }
    }
    return false;
  }

  validar_correo = (correo) => {
    const cadena = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    if (correo) {
      if (cadena.test(correo)) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  onCancel() {
    this.activeModalService.close({
      'success': false
    });
  }

  onSubmit() {

    if (this.validar()) {
      this.is_loading = true
      this.grabar()
    } else {
      this.is_loading = false
      console.log('no')
    }
  }

  async grabar() {
    const data = new FormData()
    
    data.append('jwt', this.jwt)
    data.append('lead_to_create', JSON.stringify(this.customer_to_create))
    data.append('tienda_selected', JSON.stringify(this.tienda_selected))
    data.append('merges', JSON.stringify(this.merges))
   
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Customer/create_customer`, data))
    if (response.success) {
      this.activeModalService.close(response);
    }
  }



}
