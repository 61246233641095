<div
  class="card__status__sales"
  [ngStyle]="{
    background: bgColor
  }">
  <div class="card__status__sales__content">
    <img src="{{ icon }}" alt="icono-ventas" />
    <div
      class="status__content"
      [ngStyle]="{
        color: colorFont
      }">
      <h5>{{ text }}</h5>
      <span>{{ number }}</span>
    </div>
  </div>
  <img
    class="card__status__sale__icon--big"
    src="{{ icon }}"
    alt="icono-ventas" />
</div>
