<div class="col-12 pt-5">
  <div class="card-body card-body-filtros">
    <div class="buttons card-border-shadow">
      <div class="buttons-inner">
        <div class="buttons__content">
          <!-- Botón de Filtros -->
          <app-button-dashboard
            [text]="'Filtros'"
            [ariaExpanded]="!isCollapsed"
            ariaControls="collapseExample"
            (onClick)="isCollapsed = !isCollapsed">
          </app-button-dashboard>

          <!-- Botón de Crear Venta -->
          <app-button-dashboard
            [text]="'Agregar +'"
            ariaControls="collapseExample"
            (onClick)="CrearVenta()">
          </app-button-dashboard>
        </div>
        <app-breadcrumb
          [title]="'Listado Ventas'"
          [items]="['Ventas']"></app-breadcrumb>
      </div>
      <div id="collapseExample" [ngbCollapse]="isCollapsed" class="row">
        <div class="col-12">
          <h5>Filtros</h5>
        </div>

        <div class="col-12 col-md-4">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Fecha de inicio:</label>
            <div class="col-sm-8">
              <input
                class="form-control digits"
                [(ngModel)]="filtros.fecha_inicio"
                id="example-datetime-local-input"
                type="date" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Fecha de fin:</label>
            <div class="col-sm-8">
              <input
                class="form-control digits"
                [(ngModel)]="filtros.fecha_fin"
                id="example-datetime-local-input"
                type="date" />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-4 div-button_filter">
          <button
            id="make_filter"
            type="button"
            (click)="rerender()"
            [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample"
            [attr.disabled]="buttonFiltrarTexto != 'Filtrar' ? true : null">
            <span>{{ buttonFiltrarTexto }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header-buttons">
          <app-button-dashboard [text]="'Actualizar'" (onClick)="load_orders()">
          </app-button-dashboard>
        </div>
        <!-- Componente cards estado de ventas  -->
        <div class="card-details">
          <app-card-status-sales
            [text]="'Completado'"
            [number]="23"
            [icon]="'assets/images/check.svg'"
            [bgColor]="'#28A745'">
          </app-card-status-sales>
          <app-card-status-sales
            [text]="'Procesando'"
            [number]="15"
            [icon]="'assets/images/procesando.svg'"
            [bgColor]="'#007BFF'">
          </app-card-status-sales>
          <app-card-status-sales
            [text]="'Pendiente de pago'"
            [colorFont]="'#404663'"
            [number]="8"
            [icon]="'assets/images/pago.svg'"
            [bgColor]="'#FFC107'">
          </app-card-status-sales>
          <app-card-status-sales
            [text]="'En espera'"
            [number]="12"
            [icon]="'assets/images/espera.svg'"
            [bgColor]="'#6C757D'">
          </app-card-status-sales>
        </div>
        <!-- End Componente cards estado de ventas  -->

        <div class="card-body">
          <div class="table-responsive container m-5">
            <table class="table table-concesionarios">
              <thead>
                <tr>
                  <th class="text-center" scope="col">
                    <input type="checkbox" (change)="selectAll($event)" />
                  </th>
                  <th class="text-center" scope="col"># Pedido</th>
                  <th class="text-center" scope="col">Fecha</th>

                  <th class="text-center" scope="col">Estado</th>

                  <th class="text-center" scope="col">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let o of orders" class="tr-mobile">
                  <td class="text-center align-middle">
                    <input
                      type="checkbox"
                      [(ngModel)]="o.selected"
                      (change)="updateSelection(o)" />
                  </td>

                  <td class="text-center align-middle">
                    <div class="td-mobile"># Pedido</div>
                    {{ o.order_code }}
                  </td>
                  <td class="text-center align-middle">
                    <div class="td-mobile">Fecha</div>
                    {{ o.created_time }}
                  </td>

                  <td class="text-center">
                    <div class="td-mobile">Estado</div>
                    <app-text-status-table
                      [text]="getStatusText(o.status)"
                      [statusClass]="getStatusClass(o.status)">
                    </app-text-status-table>
                  </td>

                  <td class="text-center">
                    <div class="d-flex justify-content-center">
                      <app-button-table-actions
                        [text]="'Imprimir'"
                        [ariaLabel]="'Imprimir'"
                        [iconClass]="'fa-print'"
                        additionalClasses="me-2"
                        [backgroundColor]="'#404663'"
                        (onClick)="openImprimirModal(o.comprobante)">
                      </app-button-table-actions>
                      <app-button-table-actions
                        [text]="'Eliminar'"
                        [ariaLabel]="'Eliminar'"
                        [iconClass]="'fa-trash-o'"
                        additionalClasses="me-2"
                        backgroundColor="#EB4446"
                        (onClick)="openDeleteConfirmationModal(o.id)">
                      </app-button-table-actions>
                      <app-button-table-actions
                        [text]="'Ver detalle'"
                        [ariaLabel]="'Ver detalle'"
                        [iconClass]="'fa-search'"
                        additionalClasses="me-2"
                        backgroundColor="#346ACA"
                        (onClick)="openEditonfirmationModal(o.id)">
                      </app-button-table-actions>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
