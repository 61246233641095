import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { lastValueFrom } from 'rxjs'
import { environment } from '../../environments/environment';
const Swal = require('sweetalert2')

@Injectable({
    providedIn: "root"
})
export class UsersService {
    public showLoader: boolean = false;
    public userData;
    constructor(private http: HttpClient, public router: Router) { }

    async login(email: string, password: string) {
        this.showLoader = true;

        const data = new FormData;
        data.append('email', email)
        data.append('password', password)
        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/make_login`, data))

        if (response.success) {
            console.log(response)
            this.userData = response.data
            localStorage.setItem('user', JSON.stringify(response.data));

           if(JSON.parse(localStorage.user).groups.some(group => group.id == 1)){
                    this.router.navigate(['/users']);
            } else {
              console.log('asdasdasdasd234')
                    //if ([2, 3].some(JSON.parse(localStorage.user).groups)) {
                        const data = new FormData()
                        data.append('jwt', JSON.parse(localStorage.user).jwt)
        
                        console.log('obtener tienda');
                        const response2 = <any>await lastValueFrom(this.http.post(`${environment.url_api}UserAPI/get_tiendas_asigned`, data))
                        console.log('responseeeeeee4', response2)
                        if (response2.success) {
                            if (response2.tiendas.length == 1) {
                                localStorage.setItem('tienda_selected', response2.tiendas[0].id)
                                localStorage.setItem('currency_selected', response2.tiendas[0].currency)
                                localStorage.setItem('currency_symbol_selected', response2.tiendas[0].currency_symbol)
                                this.router.navigate(['/'])
                            } else {
                                localStorage.setItem('tienda_selected', response2.tiendas[0].id)
                                localStorage.setItem('currency_selected', response2.tiendas[0].currency)
                                localStorage.setItem('currency_symbol_selected', response2.tiendas[0].currency_symbol)
                                this.router.navigate(['/asesor-select-tienda'])
                            }
                        } else {
                            console.log(response.message)
                        }
               
            }
        } else {
            this.showLoader = false
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: response.message,
            });
        }
        this.showLoader = false
    }

    async requestPasswordReset(email: string): Promise<any> {
        this.showLoader = true;
        const data = new FormData();
        data.append('email', email);
        
        try {
          const response = await lastValueFrom(
            this.http.post(`${environment.url_api}ClientAPI/forgot_password_email`, data)
          );
          return response;
        } catch (error) {
          throw error;
        } finally {
          this.showLoader = false;
        }
    }

    async validateTokenResetPassword(token: string): Promise<any> {
        this.showLoader = true;
        const data = new FormData();
        data.append('token', token);
        
        try {
          const response = await lastValueFrom(
            this.http.post(`${environment.url_api}ClientAPI/validate_token_forgot_password`, data)
          );
          return response;
        } catch (error) {
          throw error;
        } finally {
          this.showLoader = false;
        }
    }

    async actualizarPassword(password, usuario_id): Promise<any> {
      this.showLoader = true;
      const data = new FormData();
      data.append('password', password);
      data.append('usuario_id', usuario_id);

      try {
        const response = await lastValueFrom(
          this.http.post(`${environment.url_api}ClientAPI/actualizar_password`, data)
        );
        return response;
      } catch (error) {
        throw error;
      } finally {
        this.showLoader = false;
      }
  }



    SetUserData(user: any) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user')!);
    }
}