<button
  [id]="id"
  [class]="additionalClasses"
  [attr.aria-lebelled]="ariaLabel"
  (click)="handleClick()"
  [ngStyle]="{
    background: backgroundColor,
    color: textColor
  }">
  <i class="fa" [ngClass]="iconClass"></i>
  {{ text }}
</button>
