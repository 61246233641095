<div class="detail-header decoration-header bg-primary text-white"></div>
<div class="container">
    <div *ngIf="product['product_category_id'] != 'combo'" class="row">
   
        <ng-container *ngIf="products['qty_extras'] && products['qty_extras'] == 0; else withExtras">
           <div class="col-4">
                <img src="{{products['photo']}}" alt="{{products['name']}}" class="image-detail img-fluid w-100">
           </div>
           <div class="col-8">
                <h1>{{products['name']}}</h1>
                <div *ngIf="products['product_type'] == 2" class="bg-white content-variables">
                    <div *ngIf="variables_product.length != 0" class="col-12 col-md-12 row">
                        <div class="col-12 col-md-8" *ngFor="let item of atributes_product | keyvalue">
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <p><b>{{ item.key }}</b></p>
                                </div>
                                <div class="col-12">
                                    <div class="extras-container-azucar">
                                        <button 
                                            *ngFor="let option of item.value" 
                                            type="button"
                                            class="extra-item-btn"
                                            [class.selected]="selectedAttributes[item.key] === option"
                                            (click)="onButtonSelectAttribute(item.key, option)">
                                            {{ option }}
                                        </button>
                                    </div>
                                    
                                    <!-- <ng-select 
                                            class="bg-white 22"
                                            [(ngModel)]="selectedAttributes[item.key]" 
                                            [items]="item.value" 
                                            bindLabel="item" 
                                            placeholder="Seleccionar {{item.key}}"
                                            (change)="onChangeVariable()">
                                    </ng-select> -->
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <ng-container *ngIf="products['extras_azucar'].length > 0" >
                    <div class="bg-white content-variables mt-3">
                        <p> 
                            <b>Elige tu azúcar favorita</b> 
                        </p>
    
                        <div *ngIf="products['extras_azucar']">
                            <div class="extras-container-azucar">
                                <button 
                                *ngFor="let option of products['extras_azucar']"
                                type="button"
                                class="extra-item-btn"
                                [class.selected]="selectedExtrasAzucar === option.id"
                                (click)="onButtonSelect(option.id)">
                                {{ option.name }}
                                </button>
                                
                            </div>
                        </div>
                    </div>
                  
                </ng-container>
                <div *ngIf="products['product_type'] == 2" class="row mt-3">
                    <div class="col-7">
                        <div class="row align-items-center">

                            <div class="col-3">
                                <label  class="cantidad_label" for="cantidadProducto">Cantidad: </label>
                            </div>

                            <div class="col-6 form-group ">
                                    <div class="input-group">
                                        <!-- Botón izquierdo con redondeo solo a la izquierda -->
                                        <div class="input-group-prepend">
                                            <button
                                                class="btn-qty btn btn-secondary border-right-0 rounded-left"
                                                type="button"
                                                (click)="decrement()"
                                            >
                                                -
                                            </button>
                                        </div>
                                
                                        <!-- Input central sin bordes redondeados -->

                                        <p class="valor-qty">{{cantidadProducto}}</p>
                                    
                                
                                        <!-- Botón derecho con redondeo solo a la derecha -->
                                        <div class="input-group-append">
                                            <button
                                                class="btn-qty btn btn-secondary border-left-0 rounded-right"
                                                type="button"
                                                (click)="increment()"
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                            </div>
                    </div>
                    </div>
                    <div class="col-5"> 
                        <div class="price">Subtotal:  {{currency_symbol_selected}} {{ price_variable | currency: currency_selected: ''}}</div>
                    </div>
                </div>
                <div *ngIf="products['product_type'] == 1" class="row mt-3">
                    <div class="col-12">
                        <div class="row align-items-center">

                            <div class="col-3">
                                <label  class="cantidad_label" for="cantidadProducto">Cantidad: </label>
                            </div>

                            <div class="col-6 form-group ">
                                    <div class="input-group">
                                        <!-- Botón izquierdo con redondeo solo a la izquierda -->
                                        <div class="input-group-prepend">
                                            <button
                                                class="btn-qty btn btn-secondary border-right-0 rounded-left"
                                                type="button"
                                                (click)="decrement()"
                                            >
                                                -
                                            </button>
                                        </div>
                                
                                        <!-- Input central sin bordes redondeados -->

                                        <p class="valor-qty">{{cantidadProducto}}</p>
                                    
                                
                                        <!-- Botón derecho con redondeo solo a la derecha -->
                                        <div class="input-group-append">
                                            <button
                                                class="btn-qty btn btn-secondary border-left-0 rounded-right"
                                                type="button"
                                                (click)="increment()"
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                            </div>
                    </div>
                    </div>
                    <div class="col-12"> 
                        <div class="row align-items-center">
                            <div class="col-3 price" style="text-align: left;">Subtotal:</div>
                            <div class="price col-6 form-group" style="text-align: left;margin-bottom: 0;">{{currency_symbol_selected}} {{ price_variable | currency: currency_selected: ''}}</div>
                        </div>
                        <!-- <div class="price" style="text-align: left;">Subtotal:  {{currency_symbol_selected}} {{ price_variable | currency: currency_selected: ''}}</div> -->
                    </div>
                </div>

                <div class="form-group buttons" style="margin-top:10px;">
                    <button [attr.disabled]="is_loading? true:null" class="btn btn-primary" (click)="onSubmit()">Agregar</button>
                    <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button>
                </div>
           </div>
          
        </ng-container>
        <ng-template #withExtras>
            <div class="col-12">
                <h1>{{products['name']}}</h1>
                <div *ngIf="products['product_type'] == 2" class="bg-white content-variables">
                    <div *ngIf="variables_product.length != 0" class="col-12 col-md-12 row">
                        <div class="col-12 col-md-8" *ngFor="let item of atributes_product | keyvalue">
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <p><b>{{ item.key }}</b></p>
                                </div>
                                <div class="col-12">
                                    <div class="extras-container-azucar">
                                        <button 
                                            *ngFor="let option of item.value" 
                                            type="button"
                                            class="extra-item-btn"
                                            [class.selected]="selectedAttributes[item.key] === option"
                                            (click)="onButtonSelectAttribute(item.key, option)">
                                            {{ option }}
                                        </button>
                                    </div>
                                    
                                    <!-- <ng-select 
                                            class="bg-white 22"
                                            [(ngModel)]="selectedAttributes[item.key]" 
                                            [items]="item.value" 
                                            bindLabel="item" 
                                            placeholder="Seleccionar {{item.key}}"
                                            (change)="onChangeVariable()">
                                    </ng-select> -->
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-12">
                <div *ngIf="products['extras_azucar'] && products['extras_azucar'].length > 0" class="bg-white content-variables mt-3">
                    <ng-container *ngIf="products['extras_azucar'].length > 0" >
                        <p> 
                            <b>Elige tu azúcar favorita</b> 
                        </p>

                        <div *ngIf="products['extras_azucar']">
                            <div class="extras-container-azucar">
                                <button 
                                *ngFor="let option of products['extras_azucar']"
                                type="button"
                                class="extra-item-btn"
                                [class.selected]="selectedExtrasAzucar === option.id"
                                (click)="onButtonSelect(option.id)">
                                {{ option.name }}
                                </button>
                                    
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-4">
                    <img src="{{products['photo']}}" alt="{{products['name']}}" class="image-detail img-fluid w-100">
                </div>
                <div class="col-8">
                    <div *ngIf="product['product_category_id'] == 'combo'">
                     
                         <div *ngFor="let group of groupedProducts"> 
                            
                            <div class="grupo" *ngFor="let item of group.products">
                                
                                <label>
                                    <input [value]="item.variable.id" 
                                           type="checkbox" 
                                           [checked]="isChecked(item)"
                                           (change)="onCheckboxChangeCombo($event,item)">
                                    {{ item.producto.name }} 
                                    <span *ngIf="item.variable">- {{ item.variable.name }}</span>
                                  </label>
                           
                            </div>
                        </div> 
                    </div>
            
                    <div *ngIf="product['product_category_id'] != 'combo'">
                        <div *ngIf="products['qty_extras'] > 0">
                            <div class="card mb-3">
                                <div class="card-body p-4 scrollable-card">
                                    <ng-container *ngIf="products['qty_extras'] > 0" >
                                        <p> 
                                            <b>Salsas </b> 
                                            <label class="text-secondary">(Elegir máximo {{ products['qty_extras'] }} opciones)</label> 
                                        </p>
                                        
                                        <div *ngIf="products['qty_extras'] != 0">
                                            <div *ngFor="let option of products['extras']; let i = index" class="mb-2">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <img width="20px" src="../../assets/images/churromania/salsa.png" alt="image-detail" class="img-fluid m-2">
                                                    
                                                    <span 
                                                        [ngClass]="{'variable-selected': getSelectedExtra(option.id)?.count > 0, 'no-selected': !getSelectedExtra(option.id)?.count > 0}"
                                                        class="flex-grow-1">
                                                        {{ option.name }} - {{ currency_symbol_selected }} {{ option.price }}
                                                    </span>
                                        
                                                    <div class="d-flex align-items-center">
                                                        <button *ngIf="getSelectedExtra(option.id)?.count > 0"
                                                            [ngClass]="{'btn-qty': getSelectedExtra(option.id)?.count > 0, 'btn-qty-no-selected': !getSelectedExtra(option.id)?.count > 0}"
                                                            class="btn btn-secondary me-2"
                                                            [disabled]="!getSelectedExtra(option.id)?.count > 0"
                                                            (click)="decrementExtra(i)">
                                                            -
                                                        </button>
                                                        <span *ngIf="getSelectedExtra(option.id)?.count > 0" 
                                                            class="mx-2">{{ getSelectedExtra(option.id)?.count || 0 }}</span>
                                                        <button 
                                                            [ngClass]="{'btn-qty': getSelectedExtra(option.id)?.count > 0, 'btn-qty-no-selected': !getSelectedExtra(option.id)?.count > 0}"
                                                            class="btn btn-secondary"
                                                            [disabled]="totalSelectedExtras >= products['qty_extras']"
                                                            (click)="incrementExtra(i)">
                                                            +
                                                        </button>
                                                    </div>
                                                </div>
                                                <hr class="m-1" *ngIf="products['extras'].length > 1 && option !== products['extras'][products['extras'].length - 1]">
                                            </div>
                                        </div>
                                    </ng-container>
        
                                    
                                </div>
                            </div>
                            
                            
                            
                            
                        </div>
                      
                        <div class="row">
                            <div class="col-7">
                                <div class="row align-items-center">
        
                                    <div class="col-3">
                                        <label  class="cantidad_label" for="cantidadProducto">Cantidad: </label>
                                    </div>
        
                                    <div class="col-6 form-group ">
                                            <div class="input-group">
                                                <!-- Botón izquierdo con redondeo solo a la izquierda -->
                                                <div class="input-group-prepend">
                                                    <button
                                                        class="btn-qty btn btn-secondary border-right-0 rounded-left"
                                                        type="button"
                                                        (click)="decrement()"
                                                    >
                                                        -
                                                    </button>
                                                </div>
                                        
                                                <!-- Input central sin bordes redondeados -->
        
                                                <p class="valor-qty">{{cantidadProducto}}</p>
                                             
                                        
                                                <!-- Botón derecho con redondeo solo a la derecha -->
                                                <div class="input-group-append">
                                                    <button
                                                        class="btn-qty btn btn-secondary border-left-0 rounded-right"
                                                        type="button"
                                                        (click)="increment()"
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                    </div>
                            </div>
                            </div>
                            <div class="col-5"> 
                                <div class="price">Subtotal:  {{currency_symbol_selected}} {{ price_variable | currency: currency_selected: ''}}</div>
                            </div>
                        </div>
                    
                    </div>
        
                    <div class="form-group buttons" style="margin-top:10px;">
                        <button [attr.disabled]="is_loading? true:null" class="btn btn-primary" (click)="onSubmit()">Agregar</button>
                        <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button>
                    </div>
                </div>
            </div>
        </ng-template>

    </div>
    <div *ngIf="product['product_category_id'] == 'combo'" class="row">
        <div class="row">
            <div class="col-12">
                    <h1>{{product['name']}}</h1>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-4">
                <img src="{{product['photo']}}" alt="image-detail" class="image-detail img-fluid w-100">
            </div>
            <div class="col-8">
                <p class="detail-combo">{{product['description']}}</p>
                <div *ngIf="product['product_category_id'] == 'combo'">
             
                    <div *ngFor="let group of groupedProducts;let i = index"> 
                        <div class="d-flex align-items-center justify-content-start" *ngFor="let item of group.products; let x = index">
                        </div>
                       <div class="grupo" *ngFor="let item of group.products">
                           
                           <label>
                               <input [value]="item.variable.id" 
                                      type="checkbox" 
                                      [checked]="isChecked(item)"
                                      (change)="onCheckboxChangeCombo($event,item)">
                               {{ item.producto.name }} 
                               <span *ngIf="item.variable">- {{ item.variable.name }}</span>
                             </label>
                             
                       </div>
                       <hr>
                   </div> 
               </div>

               <div class="form-group buttons" style="margin-top:10px;">
                <button [attr.disabled]="is_loading? true:null" class="btn btn-primary" (click)="onSubmit()">Agregar</button>
                <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button>
            </div>
            </div>
        </div>

    </div>
</div>

