import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-button-dashboard",
  templateUrl: "./button-dashboard.component.html",
  styleUrls: ["./button-dashboard.component.scss"],
})
export class ButtonDashboardComponent {
  @Input() id?: string = "";
  @Input() text: string = "";
  @Input() ariaExpanded?: boolean;
  @Input() ariaControls?: string;
  @Input() additionalClasses: string = "";
  @Input() backgroundColor: string = "#346ACA";
  @Input() textColor: string = "#FFFFFF";
  @Output() onClick = new EventEmitter<void>();

  handleClick(): void {
    this.onClick.emit();
  }
}
