import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-button-table-actions",
  templateUrl: "./button-table-actions.component.html",
  styleUrls: ["./button-table-actions.component.scss"],
})
export class ButtonTableActionsComponent implements OnInit {
  @Input() id?: string = "";
  @Input() text: string = "";
  @Input() ariaLabel?: string = "";
  @Input() additionalClasses: string = "";
  @Input() iconClass: string = "";
  @Input() backgroundColor: string = "#346ACA";
  @Input() textColor: string = "#FFFFFF";
  @Output() onClick = new EventEmitter<void>();

  handleClick(): void {
    this.onClick.emit();
  }

  ngOnInit(): void {}
}
